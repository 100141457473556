/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #000;
$color2:              #c18f2c; //mustard, link
$color3:              #DFCA98; // dark mustard, link hover
$color4:              #efe4cb; // light yellow
$color5:              #f3f3f4; // light grey
$color6:              #e7e8ea; // slightly darker grey

// TYPOGRAPHY
// ---------------

$textColor:           #333;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Inter", Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color1;

$hrColor:             #efeeee;
